<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >

    <v-btn
      v-if="$vuetify.breakpoint.smAndDown"
      class="mr-3"
      elevation="4"
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-view-dashboard-outline</v-icon>
    </v-btn>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/portal-management"
    >
      <v-icon>mdi-bullseye</v-icon>
    </v-btn>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/reports"
    >
      <v-icon>mdi-file-chart-outline</v-icon>
    </v-btn>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/marketing-catalogue"
    >
      <v-icon>mdi-book-open-page-variant-outline</v-icon>
    </v-btn>

    <v-menu
      v-if="isFrogAdmin"
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-database</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <v-list-item v-for="(item, i) in dataItems" :key="i" :to="item.to">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list
        v-if="!$auth.loading"
        :tile="false"
        flat
        nav
      >

        <app-bar-item v-if="!$auth.isAuthenticated">
          <v-icon>mdi-login</v-icon>
          <v-list-item-title class="text-center" @click="login">Login</v-list-item-title>
        </app-bar-item>

        <v-list-item @click="logout" v-if="$auth.isAuthenticated">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from 'vuetify/lib'

// Utilities
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'DashboardCoreAppBar',

  components: {
    AppBarItem: {
      render (h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(VListItem, {
                attrs: this.$attrs,
                class: {
                  'black--text': !hover,
                  'white--text secondary elevation-12': hover
                },
                props: {
                  activeClass: '',
                  dark: hover,
                  link: true,
                  ...this.$attrs
                }
              }, this.$slots.default)
            }
          }
        })
      }
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    profile: [
      { title: 'Profile', to: 'user' },
      { divider: true },
      { title: 'Log In' },
      { title: 'Log out' }
    ],
    dataItems: [
      { title: 'Groups', icon: 'mdi-file-table-box-multiple-outline', to: '/manage/groups' },
      { title: 'Listings', icon: 'mdi-clipboard', to: '/manage/listings' },
      { title: 'Videos', icon: 'mdi-play-box-multiple-outline', to: '/manage/videos' },
      { title: 'Resources', icon: 'mdi-folder-outline', to: '/manage/resources' },
      { title: 'Marketing', icon: 'mdi-book-open-page-variant-outline', to: '/manage/marketing' },
      { title: 'Broadcast', icon: 'mdi-broadcast', to: '/marketing/broadcast' },
      { title: 'Tags', icon: 'mdi-tag-multiple-outline', to: '/manage/tags' }
    ]
  }),

  computed: {
    ...mapState(['drawer']),
    isFrogAdmin () {
      const userRole = []
      this.$auth.user['https://userrolesfrog.admin/user_authorization'].roles.forEach(element => {
        userRole.push(element)
      })
      if (userRole.includes('Frog Admin')) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER'
    }),
    login () {
      this.$auth.loginWithRedirect()
    },
    logout () {
      localStorage.clear()
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
}
</script>
